import React, { memo } from 'react';

const TosText = () => {
  const tosLink = link => {
    return (
      <a href={I18n.t(`users.sign_up.links.${link}`)} target='_blank' rel='noopener noreferrer'>
        {I18n.t(`devise.registrations.${link}`)}
      </a>
    );
  };

  return (
    <React.Fragment>
      {I18n.t('devise.registrations.tos_checkbox')} {tosLink('tos_link')}, {tosLink('pp_link')}{' '}
      {I18n.t('devise.registrations.and')} {tosLink('crp_link')}
    </React.Fragment>
  );
};

export default memo(TosText);
