import React, { memo, useState } from 'react';
import 'lodash';
import { createTwoFactorAuthenticationRequest } from 'requests/users/two_factor_authentication/two_factor_authentication';
import { ButtonStyle, Container, InputStyled, InternalForm, Label } from './style';
import UsersTwoFactorAuthenticationSmsForm from '../two_factor_authentication_sms';
import { showNotification } from 'components/FlashMessages';

const UsersTwoFactorAuthenticationsNew = ({ otpDeliveryMethod }) => {
  const [otpAttempt, setOtpAttempt] = useState('');
  const [errors, setErrors] = useState([]);

  const t = key => I18n.t(`users.two_factor_authentications.new.${key}`);

  const showError = message => {
    showNotification({ level: 'error', message: message });
  };

  const onError = (error, field) => {
    setErrors([error[field]]);
  };

  const onOtpAttemptChange = e => {
    setErrors('');
    setOtpAttempt(e.target.value);
  };

  const onSubmit = e => {
    e.preventDefault();

    createTwoFactorAuthenticationRequest(otpAttempt)
      .then(data => {
        Turbo.visit(data.redirect_path);
      })
      .catch(e => {
        onError(e.errors, 'otp_attempt');
        showError(I18n.t('helpers.failure'));
      });
  };

  const otpLabel = () => {
    switch (otpDeliveryMethod) {
      case 'sms':
        return t('code_from_sms');
      case 'qr_code':
        return t('code_from_authenticator_app');
    }
  };

  const smsForm = () => {
    return otpDeliveryMethod === 'sms' && <UsersTwoFactorAuthenticationSmsForm smsAlreadySent />;
  };

  return (
    <Container>
      {smsForm()}
      <InternalForm>
        <Label>{otpLabel()}</Label>
        <InputStyled
          id='two-factor-otp-attempt'
          type='text'
          onChange={onOtpAttemptChange}
          value={otpAttempt}
          errors={errors}
          pattern='[0-9]'
        ></InputStyled>
      </InternalForm>
      <ButtonStyle className='primary' type='submit' disabled={!otpAttempt} onClick={onSubmit}>
        {t('verify')}
      </ButtonStyle>
    </Container>
  );
};

export default memo(UsersTwoFactorAuthenticationsNew);
