import styled, { css } from 'styled-components';
import { blue } from 'helpers/theme';

export const InputBaseStyled = styled.input`
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.form.input.default.color};
  background-color: ${props => props.theme.form.input.default.background};
  background-image: none;
  background-clip: padding-box;
  border: 1px solid ${props => props.theme.form.input.default.border};
  border-radius: 5px;
  transition: border-color ease-in-out;

  &:focus {
    outline: none;
  }

  ${props =>
    props.width &&
    css`
      max-width: ${props.width};
    `}

  ${props =>
    !props.invalid &&
    css`
      &:focus {
        color: ${props => props.theme.form.input.focus.color};
        border-color: ${props => props.theme.form.input.focus.border};
        background: ${props => props.theme.form.input.focus.background};
      }
    `}


  ${props =>
    props.invalid &&
    css`
      border-color: ${props => props.theme.form.input.danger.border};
      background: ${props => props.theme.form.input.danger.background};
      color: ${props => props.theme.form.input.danger.color};
    `}
`;

export const Error = styled.div`
  color: ${props => props.theme.form.input.danger.color};

  ${props =>
    props.width &&
    css`
      max-width: ${props.width};
    `};
`;

export const InputGroup = styled.div`
  width: 100%;

  ${InputBaseStyled} {
    border-radius: 0;
    display: inline-block;
  }

  ${InputBaseStyled}:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  ${InputBaseStyled}:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  ${InputBaseStyled}:not(:last-child) {
    border-right: none;
  }

  ${InputBaseStyled}:focus {
    border-right: 1px solid ${blue};
  }

  ${InputBaseStyled}:focus + ${InputBaseStyled} {
    border-left: none;
    padding-left: calc(1rem + 1px);
  }
`;
