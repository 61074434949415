import React, { useRef } from 'react';
import { ButtonBaseStyled } from './style';
import withTheme, { lightTheme } from 'helpers/theme';

const ButtonBase = React.forwardRef(({ children, link, ...props }, ref) => {
  if (link) {
    const buttonRef = useRef();
    return (
      <React.Fragment>
        <ButtonBaseStyled ref={buttonRef} {...props} style={{ display: 'none' }}></ButtonBaseStyled>
        <a ref={ref} href='#' onClick={() => buttonRef.current.click()}>
          {children}
        </a>
      </React.Fragment>
    );
  } else {
    return (
      <ButtonBaseStyled ref={ref} {...props}>
        {children}
      </ButtonBaseStyled>
    );
  }
});

ButtonBase.displayName = 'ButtonBase';

export default withTheme(lightTheme)(ButtonBase);
