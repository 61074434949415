import React, { useRef, useEffect } from 'react';
import NotificationSystem from 'components/NotificationSystem';

const LEVEL_MAPPING = {
  success: 'success',
  notice: 'success',
  info: 'info',
  warning: 'warning',
  alert: 'warning',
  error: 'error',
  danger: 'error',
};

const FlashMessages = ({ messages }) => {
  const notifSystem = useRef();

  useEffect(() => {
    messages.forEach(notification => {
      notifSystem.current.addNotification({ level: LEVEL_MAPPING[notification[0]], message: notification[1] });
    });
  }, [JSON.stringify(messages)]);

  useEffect(() => {
    const handleNotification = (e) => {
      showNotification(e.detail.notification);
    };

    document.addEventListener('identifyy_notification', handleNotification);

    return () => {
      document.removeEventListener('identifyy_notification', handleNotification);
    };
  }, []);

  const showNotification = (notification) => {
    notifSystem.current.addNotification(notification);
  };

  return <NotificationSystem ref={notifSystem} />;
};

export const showNotification = (notification) => {
  const event = new CustomEvent('identifyy_notification', { detail: { notification } });
  document.dispatchEvent(event);
};

export default FlashMessages;
