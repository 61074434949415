import React, { memo, useState, useRef } from 'react';
import { UserSignUpStyled, SignUpForm, InputStyled, SignIn, Row, Tos, Spinner } from './style.jsx';
import IdentifyyLogo from 'components/common/identifyy_logo';
import GoogleButton from '../common/google_button';
import FacebookButton from '../common/facebook_button';
import EnvatoButton from '../common/envato_button';
import SubmitButton from '../common/submit_button';
import Column from '../common/column';
import Header from '../common/header';
import TosText from '../common/tos_text';
import { EMAIL_MATCH } from 'constants/matches';
import { signUpRequest } from 'requests/users/devise/sign_up';
import { showNotification } from 'components/FlashMessages';
import Captcha from 'components/common/captcha/index.jsx';

const UserSignUp = params => {
  const [email, setEmail] = useState('');
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [tos, setTos] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const [errors, setErrors] = useState({
    email: '',
    email_confirmation: '',
    password: '',
    password_confirmation: '',
    tos: '',
  });
  const [processing, setProcessing] = useState(false);

  const captchaRef = useRef();

  const onClickSignUp = e => {
    e.preventDefault();

    if (!tos) {
      setErrors({ ...errors, tos: [I18n.t('users.sign_up.errors.mandatory_field')] });
    } else {
      signUp();
    }
  };

  const signUp = () => {
    setProcessing(true);
    const user = {
      email: email,
      email_confirmation: emailConfirmation,
      password: password,
      password_confirmation: passwordConfirmation,
      tos: tos,
    };
    const data = {
      utf8: '✓',
      authentication_token: $('meta[name=\'csrf-token\']').attr('content'),
      user,
      commit: 'Sign Up With Email',
      captcha_token: captchaToken,
    };

    signUpRequest(data)
      .then(() => {
        setErrors([]);
      })
      .catch(error => {
        showNotification({ level: 'error', message: error.message || I18n.t('helpers.failure') });
        captchaRef.current?.reset();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onChangeEmail = value => {
    if (value && !EMAIL_MATCH.test(value)) {
      setErrors({ ...errors, email: [I18n.t('users.sign_up.errors.invalid_email')] });
    } else if (emailConfirmation && value != emailConfirmation) {
      setErrors({ ...errors, email_confirmation: [I18n.t('users.sign_up.errors.email_confirmation')] });
    } else {
      setErrors({ ...errors, email: [], email_confirmation: [] });
    }

    setEmail(value);
  };

  const onChangeEmailConfirmation = value => {
    if (value.length > 0 && value != email) {
      setErrors({ ...errors, email_confirmation: [I18n.t('users.sign_up.errors.email_confirmation')] });
    } else {
      setErrors({ ...errors, email_confirmation: [] });
    }

    setEmailConfirmation(value);
  };

  const onChangePassword = value => {
    if (value.length < 6 && value.length != 0) {
      setErrors({ ...errors, password: [I18n.t('users.sign_up.errors.password_length')] });
    } else if (passwordConfirmation && value != passwordConfirmation) {
      setErrors({ ...errors, password_confirmation: [I18n.t('users.sign_up.errors.password_confirmation')] });
    } else {
      setErrors({ ...errors, password: [], password_confirmation: [] });
    }

    setPassword(value);
  };

  const onChangePasswordConfirmation = value => {
    if (value.length > 0 && value != password) {
      setErrors({ ...errors, password_confirmation: [I18n.t('users.sign_up.errors.password_confirmation')] });
    } else {
      setErrors({ ...errors, password_confirmation: [] });
    }

    setPasswordConfirmation(value);
  };

  const onChangeTos = value => {
    if (!value) {
      setErrors({ ...errors, tos: [I18n.t('users.sign_up.errors.mandatory_field')] });
    } else {
      setErrors({ ...errors, tos: [] });
    }

    setTos(value => !value);
  };

  const formValid = () => {
    return (
      email.length > 0 &&
      password.length > 0 &&
      emailConfirmation.length > 0 &&
      passwordConfirmation.length > 0 &&
      errors['email'] == '' &&
      errors['email_confirmation'] == '' &&
      errors['password'] == '' &&
      errors['password_confirmation'] == ''
    );
  };

  return (
    <UserSignUpStyled>
      <Column>
        <IdentifyyLogo />
        <SignUpForm>
          <Header>{I18n.t('users.sign_up.title')}</Header>
          <InputStyled
            placeholder={I18n.t('users.sign_up.placeholder.email')}
            id='email'
            type='email'
            value={email}
            onChange={e => onChangeEmail(e.target.value)}
            errors={errors['email']}
          ></InputStyled>
          <InputStyled
            placeholder={I18n.t('users.sign_up.placeholder.repeat_email')}
            id='emailConfirmation'
            type='email'
            value={emailConfirmation}
            onChange={e => onChangeEmailConfirmation(e.target.value)}
            errors={errors['email_confirmation']}
          ></InputStyled>
          <InputStyled
            placeholder={I18n.t('users.sign_up.placeholder.password')}
            id='password'
            type='password'
            value={password}
            onChange={e => onChangePassword(e.target.value)}
            errors={errors['password']}
          ></InputStyled>
          <InputStyled
            placeholder={I18n.t('users.sign_up.placeholder.repeat_password')}
            id='passwordConfirmation'
            type='password'
            value={passwordConfirmation}
            onChange={e => onChangePasswordConfirmation(e.target.value)}
            errors={errors['password_confirmation']}
          ></InputStyled>
          <Row>
            <Tos
              label={<TosText />}
              id='tos'
              checked={tos}
              errors={errors['tos']}
              value={tos}
              onChange={e => onChangeTos(e.target.value)}
            ></Tos>
          </Row>
          {params.captcha && (
            <Row>
              <Captcha onSuccess={setCaptchaToken} ref={captchaRef} />
            </Row>
          )}
          <SubmitButton className='primary' disabled={processing || !formValid()} type='submit' onClick={onClickSignUp}>
            {processing && <Spinner />}
            {I18n.t('devise.registrations.submit')}
          </SubmitButton>
        </SignUpForm>
        <FacebookButton />
        <GoogleButton />
        {params.envato_login && <EnvatoButton />}
        <SignIn>
          {I18n.t('devise.registrations.existing')} <a href={'sign_in'}>{I18n.t('users.sign_up.login')}</a>
        </SignIn>
      </Column>
    </UserSignUpStyled>
  );
};

export default memo(UserSignUp);
