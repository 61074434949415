import React, { memo } from 'react';
import { EnvatoButtonStyled, Button, Content, Logo, Svg, Label } from './style';
import EnvatoButtonSrc from 'images/envato-button.svg';
import AuthenticityTokenInput from 'components/common/AuthenticityTokenInput';

const EnvatoButton = () => {
  return (
    <EnvatoButtonStyled action='/users/auth/envato' method='post' data-turbo='false'>
      <Button>
        <Content>
          <Logo>
            <Svg src={EnvatoButtonSrc} alt='' role='presentation'></Svg>
          </Logo>
          <Label>{I18n.t('users.login.envato')}</Label>
        </Content>
      </Button>
      <AuthenticityTokenInput />
    </EnvatoButtonStyled>
  );
};

export default memo(EnvatoButton);
