import React, { memo } from 'react';
import { LinksStyled, Link } from './style';

const Links = () => {
  return (
    <LinksStyled>
      <Link href={'/users/sign_in'}>{I18n.t('users.forgot_password.shared_links.login')}</Link>
      {' | '}
      <Link href={'/users/sign_up'}>{I18n.t('users.forgot_password.shared_links.sign_up')}</Link>
      {' | '}
      <Link href={'/users/confirmation/new'}>
        {I18n.t('users.forgot_password.shared_links.confirmation_instructions')}
      </Link>
    </LinksStyled>
  );
};

export default memo(Links);
