import styled from 'styled-components';
import FormGroup from 'components/common/form_group';
import Checkbox from 'components/common/checkbox';
import Input from 'components/common/input';
import Container from '../common/container';

export const UserSignInStyled = styled(Container)``;

export const LoginForm = styled(FormGroup)``;

export const InputStyled = styled(Input)``;

export const SignUp = styled.p`
  padding: 0.5rem;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const RememberMe = styled(Checkbox)`
`;

export const ForgotPassword = styled.a`
  margin-left: auto;

  @media (max-width: 500px) {
    margin-left: 20px;
  }
`;
