import React, { memo, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import intlTelInput from 'intl-tel-input';
import { InputStyled } from './style';

const PhoneNumberInput = ({ id, className, onChange, value, errors, setErrors }) => {
  let input;

  const phoneNumberInputRef = useRef();

  useEffect(() => {
    input = intlTelInput(phoneNumberInputRef.current, {
      autoPlaceholder: 'aggressive',
      separateDialCode: true,
      excludeCountries: ['ru'],
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js',
    });

    input.setNumber(value || '');
  }, []);

  useLayoutEffect(() => {
    return () => {
      input.destroy();
    };
  }, [input]);

  const onChangeHandler = useCallback(() => {
    setErrors('');
    onChange(input.getNumber());
  }, [input]);

  return (
    <InputStyled
      id={id}
      ref={phoneNumberInputRef}
      type='text'
      className={`${className || 'form-control'}`}
      onChange={onChangeHandler}
      errors={errors}
    />
  );
};

export default memo(PhoneNumberInput);
