import React, { forwardRef } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';

const Captcha = forwardRef(({ onSuccess }, ref) => {
  const siteKey = process.env.RAILS_ENV === 'production' ? '0x4AAAAAAAIS_1A0tcXhLAKD' : '0x4AAAAAAAITDKZrzFdX-h5a';

  return (
    <React.Fragment>
      <Turnstile siteKey={siteKey} onSuccess={onSuccess} ref={ref} />
    </React.Fragment>
  );
});

Captcha.displayName = 'CloudflareTurnstile';

export default Captcha;
