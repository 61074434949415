import React from 'react';
import { CheckboxBaseStyled, Container, Row, Error, LabelStyled } from './style';
import withTheme, { lightTheme } from 'helpers/theme';

const CheckboxBase = React.forwardRef(({ id, label, errors = [], children, ...props }, ref) => {
  return (
    <React.Fragment>
      <Container>
        <Row>
          <CheckboxBaseStyled type='checkbox' id={id} ref={ref} {...props}>
            {children}
          </CheckboxBaseStyled>
          {label && (
            <LabelStyled htmlFor={id}>
              {label}
            </LabelStyled>
          )}
        </Row>
        <Row>{errors?.length > 0 && errors.map(error => <Error key={error}>{error}</Error>)}</Row>
      </Container>
    </React.Fragment>
  );
});

export default withTheme(lightTheme)(CheckboxBase);
