import React, { memo, useRef, useState } from 'react';
import { ForgotPasswordStyled, Form, InputStyled, Row, Text } from './style.jsx';
import Links from '../common/links';
import { EMAIL_MATCH } from 'constants/matches';
import IdentifyyLogo from 'components/common/identifyy_logo';
import SubmitButton from '../common/submit_button';
import Column from '../common/column';
import Header from '../common/header';
import { forgotPasswordRequest } from 'requests/users/devise/forgot_password';
import { showNotification } from 'components/FlashMessages';
import Captcha from 'components/common/captcha';

const ForgotPassword = params => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [captchaToken, setCaptchaToken] = useState('');

  const captchaRef = useRef();

  const onClickSubmit = e => {
    e.preventDefault();

    const user = { email };
    const data = {
      utf8: '✓',
      authentication_token: $("meta[name='csrf-token']").attr('content'),
      commit: 'Password Reset',
      user: user,
      captcha_token: captchaToken,
    };

    forgotPasswordRequest(data).catch(error => {
      showNotification({ level: 'error', message: error?.message || I18n.t('users.accounts.form.errors.notif') });
      captchaRef.current?.reset();
    });
  };

  const onChangeEmail = value => {
    if (value && !EMAIL_MATCH.test(value)) {
      setErrors([I18n.t('users.forgot_password.errors.invalid_email')]);
    } else {
      setErrors([]);
    }

    setEmail(value);
  };

  return (
    <ForgotPasswordStyled>
      <Column>
        <IdentifyyLogo />
        <Header>{I18n.t('users.forgot_password.title')}</Header>
        <Text>{I18n.t('users.forgot_password.instructions')}</Text>
        <Form>
          <InputStyled
            placeholder={I18n.t('users.login.email')}
            id='email'
            type='email'
            value={email}
            onChange={e => onChangeEmail(e.target.value)}
            errors={errors}
          ></InputStyled>
          {params.captcha && (
            <Row>
              <Captcha onSuccess={setCaptchaToken} ref={captchaRef} />
            </Row>
          )}
          <SubmitButton className='primary' disabled={!email} type='submit' onClick={onClickSubmit}>
            {I18n.t('users.forgot_password.send_instructions')}
          </SubmitButton>
        </Form>
        <Links />
      </Column>
    </ForgotPasswordStyled>
  );
};

export default memo(ForgotPassword);
