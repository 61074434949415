import React from 'react';
import ReactDOM from 'react-dom';
import intersection from 'lodash/intersection';
import _ from 'lodash';
import setupLocale from 'helpers/setupLocale';

const CLASS_ATTRIBUTE_NAME = 'data-react-class';
const PROPS_ATTRIBUTE_NAME = 'data-react-props';

class ReactRenderer {
  constructor() {
    this.registeredComponents = {};
  }

  render = (node, component) => {
    const propsJson = node.getAttribute(PROPS_ATTRIBUTE_NAME);
    const props = propsJson && JSON.parse(propsJson);

    const reactElement = React.createElement(component, props);

    ReactDOM.render(reactElement, node);
  };

  registerComponents = components => {
    const collisions = intersection(_.keys(this.registeredComponents), _.keys(components));
    if (collisions.length > 0) {
      console.error(`Can not register components. Following components are already registered: ${collisions}`);
    }

    _.assign(this.registeredComponents, _.omit(components, collisions));
    return true;
  };

  unmountComponents = () => {
    const mounted = document.querySelectorAll(`[${CLASS_ATTRIBUTE_NAME}]`);
    mounted.forEach(ReactDOM.unmountComponentAtNode);
  };

  mountComponents = () => {
    setupLocale();
    const toMount = document.querySelectorAll(`[${CLASS_ATTRIBUTE_NAME}]`);

    toMount.forEach(node => {
      const className = node.getAttribute(CLASS_ATTRIBUTE_NAME);
      const component = this.registeredComponents[className];

      if (component) {
        if (node.innerHTML.length === 0) this.render(node, component);
      } else {
        console.error(`Can not render a component that has not been registered: ${className}`);
      }
    });
  };

  setup = (components = {}) => {
    if (typeof window.ReactRenderer === 'undefined') {
      window.ReactRenderer = this;

      document.addEventListener('turbo:load', this.mountComponents, { once: true });
      document.addEventListener('turbo:render', this.mountComponents);
      document.addEventListener('turbo:before-render', this.unmountComponents);
    }

    window.ReactRenderer.registerComponents(components);
    window.ReactRenderer.mountComponents();
  };
}

export default ReactRenderer;
