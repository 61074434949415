import Client from 'requests/client';

const client = new Client({
  basePath: '/users',
  dataType: false,
  headers: {
    'No-Turbo-Redirect': true,
  },
});

export const signUpRequest = async data => {
  return client.create({ data });
};
