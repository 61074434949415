import React, { memo } from 'react';
import { FacebookButtonStyled, Button, Content, Logo, Svg, Label } from './style';
import FacebookButtonSrc from 'images/facebook-button.svg';
import AuthenticityTokenInput from 'components/common/AuthenticityTokenInput';

const FacebookButton = () => {
  return (
    <FacebookButtonStyled action='/users/auth/facebook' method='post' data-turbo='false'>
      <Button>
        <Content>
          <Logo>
            <Svg src={FacebookButtonSrc} alt='' role='presentation'></Svg>
          </Logo>
          <Label>{I18n.t('users.login.fb')}</Label>
        </Content>
      </Button>
      <AuthenticityTokenInput />
    </FacebookButtonStyled>
  );
};

export default memo(FacebookButton);
