import React, { useState, useEffect } from 'react';
import { CookieBannerStyled, Content, Buttons, Button } from './style';
import Cookies from 'js-cookie';

const CookieBanner = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!Cookies.get('identifyy-cookieAllowed')) {
      setVisible(true);
    }
  }, []);

  const onAgreeClick = () => {
    setVisible(false);
    Cookies.set('identifyy-cookieAllowed', true, { expires: 60 });
  };

  return (
    visible && (
      <div className='cookie-banner'>
        <CookieBannerStyled>
          <Content>{I18n.t('cookie_banner.text')}</Content>
          <Buttons>
            <Button className='primary' onClick={onAgreeClick}>
              {I18n.t('cookie_banner.agree_button_text')}
            </Button>
          </Buttons>
        </CookieBannerStyled>
      </div>
    )
  );
};

export default CookieBanner;
