import React from 'react';
import ReactNotificationSystem from 'react-notification-system';
import { NotificationSystemStyled } from './style';

import { green, light_red, white, yellow, blue } from 'helpers/theme';

const STYLE = {
  NotificationItem: {
    DefaultStyle: {
      border: 'none',
      borderTop: 'none',
      borderRadius: '5px',
      padding: '10px 15px',
      color: white,
      fontSize: '14px',
      fontWeight: '500',
    },

    success: {
      backgroundColor: green,
    },

    error: {
      backgroundColor: light_red,
    },

    warning: {
      backgroundColor: yellow,
    },

    info: {
      backgroundColor: blue,
    },
  },

  Dismiss: {
    DefaultStyle: {
      border: 'none',
      backgroundColor: 'inherit',
      color: 'inherit',
      fontSize: '20px',
    },
  },
};

const NotificationSystem = React.forwardRef((_props, ref) => {
  return (
    <NotificationSystemStyled>
      <ReactNotificationSystem ref={ref} style={STYLE} />
    </NotificationSystemStyled>
  );
});
NotificationSystem.displayName = 'NotificationSystem';

export default NotificationSystem;
