import React from 'react';
import { InputBaseStyled, Error } from './style';
import withTheme, { lightTheme } from 'helpers/theme';
import Label from 'components/common/label';

const InputBase = React.forwardRef(({ label, id, errors = [], ...props }, ref) => {

  // Allow to pass single error as a string
  errors = [errors].flat().filter(error => error);

  return (
    <React.Fragment>
      {label && <Label htmlFor={id}>{label}</Label>}
      <InputBaseStyled ref={ref} id={id} {...props} invalid={props.invalid || errors?.length > 0} />
      {errors?.length > 0 && errors.map(error => <Error key={error}>{error}</Error>)}
    </React.Fragment>
  );
});

export default withTheme(lightTheme)(InputBase);
