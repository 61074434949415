import React, { memo } from 'react';
import { GoogleButtonStyled, Button, Content, Logo, Svg, Label } from './style';
import GoogleButtonSrc from 'images/google-button.svg';
import AuthenticityTokenInput from 'components/common/AuthenticityTokenInput';

const GoogleButton = () => {
  return (
    <GoogleButtonStyled action='/users/auth/google_oauth2' method='post' data-turbo='false'>
      <Button>
        <Content>
          <Logo>
            <Svg src={GoogleButtonSrc} alt='' role='presentation'></Svg>
          </Logo>
          <Label>{I18n.t('users.login.google')}</Label>
        </Content>
      </Button>
      <AuthenticityTokenInput />
    </GoogleButtonStyled>
  );
};

export default memo(GoogleButton);
