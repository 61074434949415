import React, { memo, useState } from 'react';
import {
  UserSignInStyled,
  LoginForm,
  InputStyled,
  Row,
  RememberMe,
} from '../../users/devise/sign_in/style.jsx';
import IdentifyyLogo from 'components/common/identifyy_logo';
import SubmitButton from '../../users/devise/common/submit_button';
import Column from '../../users/devise/common/column';
import Header from '../../users/devise/common/header';
import { adminSignInRequest } from 'requests/admin/devise/sign_in';
import { showNotification } from 'components/FlashMessages';

const AdminSignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const onClickSignIn = e => {
    e.preventDefault();

    const admin_user = { email, password, rememberMe };
    const data = {
      authenticity_token: $('meta[name=\'csrf-token\']').attr('content'),
      admin_user,
      commit: 'Login',
    };

    adminSignInRequest(data)
      .then(() => {
        setEmail('');
        setPassword('');
      })
      .catch(() => {
        showNotification({ level: 'error', message: I18n.t('devise.failure.not_found_in_database') })
      });
  };

  return (
    <UserSignInStyled>
      <Column>
        <IdentifyyLogo />
        <LoginForm>
          <Header>{I18n.t('users.login.title')}</Header>
          <InputStyled
            label={I18n.t('users.login.email')}
            id='email'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          ></InputStyled>
          <InputStyled
            label={I18n.t('users.login.password')}
            id='password'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          ></InputStyled>
          <Row>
            <RememberMe
              label={I18n.t('users.login.remember_me')}
              id='remember_me'
              checked={rememberMe}
              onChange={() => setRememberMe(value => !value)}
            ></RememberMe>
          </Row>
          <SubmitButton className='primary' disabled={!email || !password} type='submit' onClick={onClickSignIn}>
            {I18n.t('users.login.btn')}
          </SubmitButton>
        </LoginForm>
      </Column>
    </UserSignInStyled>
  );
};

export default memo(AdminSignIn);
