import styled from 'styled-components';
import Input from 'components/common/input';
import SubmitButton from '../devise/common/submit_button';

export const InputStyled = styled(Input)``;

export const Label = styled.label`
  display: inline-block;
  margin: 1rem 0 0.5rem 0;
`;

export const Container = styled.div`
  margin-top: 1rem;

  @media (max-width: 500px) {
    max-width: 80vw;
  }
`;

export const InternalForm = styled.div`
  margin-bottom: 1rem;
`;

export const ButtonStyle = styled(SubmitButton)`
  margin: auto;
  white-space: nowrap;
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;
