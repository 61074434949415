import React from 'react';
import { HeaderStyled } from './style';
import withTheme, { lightTheme } from 'helpers/theme';

const Header = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <HeaderStyled ref={ref} {...props}>
      {children}
    </HeaderStyled>
  );
});

export default withTheme(lightTheme)(Header);
