import styled from 'styled-components';

export const IdentifyyLogoBaseStyled = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 192px;
  height: 47px;
`;

export const Link = styled.a``;
