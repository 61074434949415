import Client from 'requests/client';

const client = new Client({
  basePath: '/users/password',
  dataType: false,
});

export const forgotPasswordRequest = async data => {
  return client.create({ data });
};
