import React, { useEffect, useState } from 'react';

const AuthenticityTokenInput = () => {
  const [authenticityToken, setAuthenticityToken] = useState('');

  useEffect(() => {
    setAuthenticityToken($('meta[name=\'csrf-token\']').attr('content'));
  }, []);

  return <input type='hidden' name='authenticity_token' value={authenticityToken}></input>;
};

export default AuthenticityTokenInput;
