import Client from 'requests/client';

const client = new Client({
  basePath: '/users/confirmation',
  dataType: false,
});

export const resetConfirmationRequest = async data => {
  return client.create({ data });
};
