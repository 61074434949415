import styled from 'styled-components';
import Input from 'components/common/input';
import Button from 'components/common/button';

export const InputStyled = styled(Input)``;

export const PhoneNumberBox = styled.div`
  margin-bottom: 1rem;
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const SendCodeText = styled.p`
  margin: 0;
  flex: 1;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 0;
`;

export const SendCodeAgainButton = styled(Button)`
  margin: 0;
`;

export const Box = styled.div``;

export const SmsForm = styled.div``;

export const PhoneNumberTitle = styled.p``;

export const PhoneNumberLabel = styled.label``;
