import React from 'react';
import { ContainerStyled } from './style';
import withTheme, { lightTheme } from 'helpers/theme';

const Container = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ContainerStyled ref={ref} {...props}>
      {children}
    </ContainerStyled>
  );
});

export default withTheme(lightTheme)(Container);
