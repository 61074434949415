import React from 'react';
import { ColumnStyled } from './style';
import withTheme, { lightTheme } from 'helpers/theme';

const Column = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ColumnStyled ref={ref} {...props}>
      {children}
    </ColumnStyled>
  );
});

export default withTheme(lightTheme)(Column);
