import styled from 'styled-components';

export const ColumnStyled = styled.div`
  width: 300px;
  max-width: 100%;
  padding-top: 0.5rem;

  @media (max-width: 500px) {
    padding-top: 1.5rem;
  }
`;
