import ReactRenderer from 'helpers/ReactRenderer';
import UserSignUp from 'components/users/devise/sign_up/index';
import UserSignIn from 'components/users/devise/sign_in/index';
import ForgotPassword from 'components/users/devise/forgot_password/index';
import ChangePassword from 'components/users/devise/change_password';
import ResetConfirmation from 'components/users/devise/confirmation/index';
import AdminSignIn from 'components/admin/devise/index';
import FlashMessages from 'components/FlashMessages';
import UsersTwoFactorAuthenticationsNew from 'components/users/two_factor_authentications';
import CookieBanner from 'components/CookieBanner';

(new ReactRenderer).setup({
  UserSignUp,
  UserSignIn,
  ForgotPassword,
  ChangePassword,
  ResetConfirmation,
  AdminSignIn,
  FlashMessages,
  UsersTwoFactorAuthenticationsNew,
  CookieBanner
});
