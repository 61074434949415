import styled, { css } from 'styled-components';
import Label from 'components/common/label';

export const CheckboxBaseStyled = styled.input`
  display: inline-block;
  margin: 0.25rem 0.5rem 0.5rem 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const LabelStyled = styled(Label)`
  max-width: 90%;
`;

export const Error = styled.p`
  color: ${props => props.theme.form.input.danger.color};

  ${props =>
    props.width &&
    css`
      max-width: ${props.width};
    `};
`;
