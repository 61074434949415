export default class Client {
  constructor({ basePath, dataType = 'json', contentType, processData, headers = {} }) {
    this.basePath = basePath;
    this.contentType = contentType;
    this.processData = processData;
    this.dataType = dataType;
    this.headers = headers;
  }

  onError = (xhr, callback) => {
    callback(xhr.responseJSON);
  };

  request = (path, data, method) =>
    new Promise((resolve, reject) => {
      let headers = this.headers;

      if (method != 'GET') {
        headers = {
          'X-CSRF-Token': $('meta[name=\'csrf-token\']').attr('content'),
          ...headers,
        };
      }

      $.ajax({
        method: method,
        url: `${this.basePath}/${path}`,
        data: data,
        headers: headers,
        dataType: this.dataType,
        contentType: this.contentType,
        processData: this.processData,
        success: resolve,
        error: xhr => this.onError(xhr, reject),
      });
    });

  get = ({ path = '', data = {} }) => this.request(path, data, 'GET');

  create = ({ path = '', data = {} }) => this.request(path, data, 'POST');

  update = ({ path = '', data = {}, method = 'PATCH' }) => this.request(path, data, method);

  destroy = ({ path = '', data = {} }) => this.request(path, data, 'DELETE');
}
