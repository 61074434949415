import styled, { css } from 'styled-components';
import Button from 'components/common/button';

export const SubmitButtonStyled = styled(Button)`
  font-size: 16px;
  height: 40px;
  margin: 2vh 0 5vh;
  width: 100%;

  @media (max-width: 500px) {
    font-size: 14px;
    max-width: 80vw;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}
`;
