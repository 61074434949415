import styled from 'styled-components';
import FormGroup from 'components/common/form_group';
import Checkbox from 'components/common/checkbox';
import Input from 'components/common/input';
import Container from '../common/container';
import { white, grey } from 'helpers/theme';

export const UserSignUpStyled = styled(Container)``;

export const SignUpForm = styled(FormGroup)``;

export const InputStyled = styled(Input)``;

export const SignIn = styled.p`
  padding: 0.5rem;
  text-align: center;
`;

export const Row = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const Tos = styled(Checkbox)``;

export const Spinner = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 3px solid ${white};
  border-top-color: ${grey};
  animation: spin 0.6s linear infinite;
  margin-right: 10px;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
