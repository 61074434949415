import React, { memo, useState } from 'react';
import { ConfirmationStyled, Form, InputStyled, Link } from './style.jsx';
import { EMAIL_MATCH } from 'constants/matches';
import IdentifyyLogo from 'components/common/identifyy_logo';
import SubmitButton from '../common/submit_button';
import Column from '../common/column';
import Header from '../common/header';
import { resetConfirmationRequest } from 'requests/users/devise/reset_confirmation';
import { showNotification } from 'components/FlashMessages';

const ResetConfirmation = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);

  const onClickSubmit = e => {
    e.preventDefault();

    const user = { email };
    const data = {
      utf8: '✓',
      authentication_token: $('meta[name=\'csrf-token\']').attr('content'),
      commit: 'Confirmation Instructions',
      user: user,
    };

    resetConfirmationRequest(data)
      .then((response) => {
        showNotification({ level: 'success', message: response.message })
      })
      .catch(() => {
        showNotification({ level: 'error', message: I18n.t('users.accounts.form.errors.notif') })
      });
  };

  const onChangeEmail = value => {
    if (value && !EMAIL_MATCH.test(value)) {
      setErrors([I18n.t('users.forgot_password.errors.invalid_email')]);
    } else {
      setErrors([]);
    }

    setEmail(value);
  };

  return (
    <ConfirmationStyled>
      <Column>
        <IdentifyyLogo />
        <Header>{I18n.t('users.reset_confirmation_instructions.title')}</Header>
        <Form>
          <InputStyled
            placeholder={I18n.t('users.login.email')}
            id='email'
            type='email'
            value={email}
            onChange={e => onChangeEmail(e.target.value)}
            errors={errors}
          ></InputStyled>
          <SubmitButton className='primary' disabled={!email} type='submit' onClick={onClickSubmit}>
            {I18n.t('users.reset_confirmation_instructions.title')}
          </SubmitButton>
        </Form>
        <Link>
          <a href='/users/sign_in'>{I18n.t('users.forgot_password.shared_links.login')}</a>
        </Link>
      </Column>
    </ConfirmationStyled>
  );
};

export default memo(ResetConfirmation);
