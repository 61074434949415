import styled from 'styled-components';
import FormGroup from 'components/common/form_group';
import Input from 'components/common/input';
import Container from '../common/container';

export const ChangePasswordStyled = styled(Container)``;

export const Form = styled(FormGroup)`
  padding-top: 0.5rem;
`;

export const InputStyled = styled(Input)``;

export const Row = styled.div`
  display: flex;
  margin-top: 20px;
`;
