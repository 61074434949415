import React from 'react';
import { SubmitButtonStyled } from './style';
import withTheme, { lightTheme } from 'helpers/theme';

const SubmitButton = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <SubmitButtonStyled ref={ref} {...props}>
      {children}
    </SubmitButtonStyled>
  );
});

export default withTheme(lightTheme)(SubmitButton);
