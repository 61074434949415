import styled from 'styled-components';
import ButtonBase from 'components/common/button';

export const CookieBannerStyled = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 20px;
  margin: 0;
  background-color: black;
  color: white;
  width: 360px;

  @media screen and (max-width: 500px) {
    width: calc(100% - 40px);
  }
`;

export const Content = styled.div``;

export const Buttons = styled.div`
  padding-top: 20px;
`;

export const Button = styled(ButtonBase)`
  margin: 0;
`;
