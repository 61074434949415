import React, { memo, useState } from 'react';
import 'lodash';
import { createTwoFactorAuthenticationSmsRequest } from 'requests/users/two_factor_authentication/two_factor_authentication_sms';
import PhoneNumberInput from './components/PhoneNumberInput';
import {
  Box,
  ButtonStyled,
  FlexBox,
  PhoneNumberBox,
  PhoneNumberLabel,
  PhoneNumberTitle,
  SendCodeAgainButton,
  SendCodeText,
  SmsForm,
} from './style';
import { showNotification } from 'components/FlashMessages';

const UsersTwoFactorAuthenticationSmsForm = ({
  smsAlreadySent,
  showPhoneNumberInput,
  onPhoneNumberChange,
  phoneNumber,
}) => {
  const WAIT_TIME = 10000;
  const SEND_BUTTON_READY = 0;
  const SEND_BUTTON_WAIT = 1;
  const SEND_BUTTON_RESEND = 2;
  const [errors, setErrors] = useState([]);
  const [sendButtonStatus, setSendButtonStatus] = useState(smsAlreadySent ? SEND_BUTTON_RESEND : SEND_BUTTON_READY);

  const t = key => I18n.t(`users.two_factor_authentication_sms.form.${key}`);

  const addNotification = options => showNotification(options);

  const showError = message => addNotification({ message: message, level: 'error' });

  const showMessage = message => addNotification({ message: message, level: 'success' });

  const onError = (error, field) => {
    setErrors([error[field]]);
  };

  const onSendSmsClick = () => {
    const data = showPhoneNumberInput ? { two_factor_authentication_sms: { phone_number: phoneNumber } } : {};

    createTwoFactorAuthenticationSmsRequest(data)
      .then(() => {
        showMessage(I18n.t('helpers.success'));
        setSendButtonStatus(SEND_BUTTON_WAIT);
        setTimeout(() => {
          setSendButtonStatus(SEND_BUTTON_RESEND);
        }, WAIT_TIME);
      })
      .catch(e => {
        onError(e.errors, 'phone_number');
        showError(I18n.t('helpers.failure'));
      });
  };

  const phoneNumberInput = () =>
    showPhoneNumberInput && (
      <Box>
        <PhoneNumberTitle>{t('phone_number_title')}</PhoneNumberTitle>
        <PhoneNumberBox>
          <PhoneNumberLabel>{t('phone_number_label')}</PhoneNumberLabel>
          <PhoneNumberInput
            id='phone-number-input'
            className='form-control'
            onChange={onPhoneNumberChange}
            value={phoneNumber}
            errors={errors}
            setErrors={setErrors}
          />
        </PhoneNumberBox>
      </Box>
    );

  const switchSendSmsButton = () => {
    switch (sendButtonStatus) {
      case SEND_BUTTON_READY:
        return (
          <Box>
            <ButtonStyled className='primary blank' onClick={onSendSmsClick}>
              {t('send_code')}
            </ButtonStyled>
          </Box>
        );
      case SEND_BUTTON_WAIT:
        return (
          <Box>
            <SendCodeText>
              <i className='fa fa-check mr-1' />
              {t('code_sent')}
            </SendCodeText>
          </Box>
        );
      case SEND_BUTTON_RESEND:
        return (
          <FlexBox>
            <SendCodeText>
              <i className='fa fa-check mr-1' />
              {t('code_sent')}
            </SendCodeText>
            <SendCodeAgainButton className='primary blank' onClick={onSendSmsClick}>
              {t('send_again')}
            </SendCodeAgainButton>
          </FlexBox>
        );
    }
  };

  return (
    <SmsForm>
      {phoneNumberInput()}
      {switchSendSmsButton()}
    </SmsForm>
  );
};

export default memo(UsersTwoFactorAuthenticationSmsForm);
