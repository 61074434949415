/*jshint esversion: 6 */
import React, { forwardRef } from 'react';
import { ThemeProvider } from 'styled-components';

export const green = '#1BB1A3';
export const white = 'white';
export const black = '#2c2d34';
export const grey = '#CCCCCC';
export const dark_grey = '#8E949C';
export const red = '#E02020';
export const light_red = '#f86c6b';
export const dark_orange = '#D48000';
export const orange = '#FA6400';
export const yellow = '#F7B500';
export const blue = '#20a8d8';
export const cyan = '#63c2de';
export const dark_blue = '#3b7e93';
export const light_blue = '#e1eef2';
export const light_green = '#defae2';

export const lightTheme = {
  page: {
    title: {
      color: black,
    },
  },
  form: {
    button: {
      file: {
        background: white,
        border: grey,
      },
      unSubmit: {
        background: dark_blue,
        color: white,
        borderColor: dark_blue,
      },
      unsubmit_action: {
        background: white,
        color: dark_blue,
        borderColor: dark_blue,
      },
      default: {
        background: white,
        color: green,
        borderColor: green,
      },
      success: {
        background: green,
        color: white,
        borderColor: green,
      },
      fill_green: {
        background: green,
        color: green,
        borderColor: green,
      },
      primary: {
        background: blue,
        color: white,
        borderColor: blue,
      },
      warning: {
        background: yellow,
        color: white,
        borderColor: yellow,
      },
      primary_action: {
        background: white,
        color: blue,
        borderColor: blue,
      },
      warning_action: {
        background: white,
        color: yellow,
        borderColor: yellow,
      },
      danger: {
        background: light_red,
        color: white,
        borderColor: light_red,
      },
      skip: {
        background: white,
        color: black,
        borderColor: grey,
      },
      reject_action: {
        background: white,
        color: light_red,
        borderColor: light_red,
      },
    },
    error: {
      color: red,
    },
    checkbox: {
      color: dark_grey,
      disabled: grey,
    },
    select: {
      simple: {
        color: black,
        border: grey,
        background: white,
      },
    },
    input: {
      default: {
        background: white,
        color: dark_grey,
        border: grey,
      },
      focus: {
        background: white,
        color: dark_grey,
        border: blue,
      },
      danger: {
        background: white,
        color: light_red,
        border: red,
      },
    },
  },
  accounting: {
    actions: {
      color: dark_grey,
    },
    worker: {
      base: {
        color: dark_grey,
      },
      failure: {
        color: red,
      },
      need_action: {
        color: dark_orange,
      },
      success: {
        color: green,
      },
    },
    select: {
      borderColor: grey,
    },
    details: {
      content: {
        borderColor: grey,
      },
    },
    publish: {
      published: {
        color: green,
      },
      unpublished: {
        color: orange,
      },
    },
    download_report: {
      color: green,
    },
    notification: {
      text: {
        color: dark_orange,
      },
      button: {
        borderColor: black,
      },
      upload_button: {
        background: green,
        borderColor: green,
        color: white,
      },
      continue_button: {
        borderColor: grey,
      },
    },
    steps: {
      item: {
        color: dark_grey,
      },
      active_item: {
        color: black,
      },
      error: {
        color: red,
      },
      success: {
        color: green,
      },
      continue_button: {
        background: green,
        color: white,
        borderColor: green,
      },
      back_button: {
        color: dark_grey,
      },
      recalculate_button: {
        color: dark_grey,
      },
    },
  },
  modal: {
    screen: {
      background: white,
    },
  },
  media_asset: {
    status: {
      uploaded: red,
      uploading: yellow,
      ready: green,
      invalid_reference_short: dark_grey,
      empty: dark_grey,
      invalid_reference_long: dark_grey,
      approved: green,
      submitted: yellow,
      youtube_submitted: green,
      rejected: red,
      questionnaire_needed: yellow,
      default: grey,
    },
  },
  api_user_table: {
    status: {
      active: green,
      not_active: red,
    },
  },
  old_button: {
    color: cyan,
    borderColor: cyan,
  },
};

/* jshint ignore:start */
const withTheme = theme => Component => {
  return forwardRef((props, ref) => (
    <ThemeProvider theme={theme}>
      <Component {...props} ref={ref} />
    </ThemeProvider>
  ));
};
/* jshint ignore:end */

export default withTheme;
