import React from 'react';
import { IdentifyyLogoBaseStyled, Link } from './style';
import withTheme, { lightTheme } from 'helpers/theme';

import Logo from 'images/haawk.png';

const IdentifyyLogoBase = () => {
  return (
    <Link href={I18n.t('website')} rel='noopener noreferrer' target='_blank'>
      <IdentifyyLogoBaseStyled src={Logo} alt='Identifyy'/>
    </Link>
  );
};

export default withTheme(lightTheme)(IdentifyyLogoBase);
