import styled from 'styled-components';

export const GoogleButtonStyled = styled.form`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  padding-bottom: 0.5rem;
  text-align: center;
  text-decoration: none !important;
`;

export const Button = styled.button`
  background: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: inline-block;
  padding-top: 1.5px;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  width: 100%;
  &:hover {
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    outline: none;
  }
  &:active {
    background-color: rgba(238, 238, 238, 1);
    outline: none;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  height: 50px;
  width: 100%;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const Logo = styled.div`
  height: inherit;
  padding: 11px 15px 11px;

  @media (max-width: 500px) {
    padding: 11px 10px 11px;
  }
`;

export const Svg = styled.img`
  height: 18px;
  width: 18px;
`;

export const Label = styled.div`
  color: rgba(117, 117, 117, 1);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0.21px;
  line-height: 1;
  text-align: center;
  width: 100%;
`;
