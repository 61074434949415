import styled, { css } from 'styled-components';

/* eslint-disable no-unused-vars */
export const buttonStyles = props => css`
/* eslint-enable no-unused-vars */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 35px;
  padding: 0 15px;
  margin: 10px 0 10px 10px;
  border: 1px solid ${props => props.theme.form.button.default.borderColor};
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.1s linear, width 0.1s linear;
  outline: none;
  background: ${props => props.theme.form.button.default.background};
  color: ${props => props.theme.form.button.default.color};
  font-size: 13px;
  flex-shrink: 0;

  @media (max-width: 1300px) {
		font-size: 12px;
	}

  &:active {
    transform: scale(0.96);
  }

  &:focus {
    outline: none;
  }

  &.success {
    border-color: ${props => props.theme.form.button.success.borderColor};
    background: ${props => props.theme.form.button.success.background};
    color: ${props => props.theme.form.button.success.color};

    &.blank {
      color: ${props => props.theme.form.button.success.background};
      background: ${props => props.theme.form.button.success.color};
    }
  }

  &.fill_green {
    border-color: ${props => props.theme.form.button.success.borderColor};
    background: ${props => props.theme.form.button.success.background};
    color: ${props => props.theme.form.button.success.color};

    &.blank {
      color: ${props => props.theme.form.button.success.background};
      background: ${props => props.theme.form.button.success.color};
    }
  }

  &.warning {
    border-color: ${props => props.theme.form.button.warning.borderColor};
    background: ${props => props.theme.form.button.warning.background};
    color: ${props => props.theme.form.button.warning.color};

    &.blank {
      color: ${props => props.theme.form.button.warning.background};
      background: ${props => props.theme.form.button.warning.color};
    }
  }

  &.primary {
    border-color: ${props => props.theme.form.button.primary.borderColor};
    background: ${props => props.theme.form.button.primary.background};
    color: ${props => props.theme.form.button.primary.color};

    &.blank {
      color: ${props => props.theme.form.button.primary.background};
      background: ${props => props.theme.form.button.primary.color};
    }
  }

  &.danger {
    border-color: ${props => props.theme.form.button.danger.borderColor};
    background: ${props => props.theme.form.button.danger.background};
    color: ${props => props.theme.form.button.danger.color};

    &.blank {
      color: ${props => props.theme.form.button.danger.background};
      background: ${props => props.theme.form.button.danger.color};
    }
  }

  &.inline {
    display: inline-flex;
  }

  ${props =>
    props.width &&
    css`
      max-width: ${props.width}px;
      width: 100%;
    `}

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}

  ${props =>
    props.hidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  ${props =>
    props.oldStyle &&
    css`
      margin: 0;
      color: ${props => props.theme.old_button.color};
      border-color: ${props => props.theme.old_button.borderColor};
      background-color: transparent;
    `}
`;

export const ButtonBaseStyled = styled.button(buttonStyles);
